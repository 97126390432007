define('ember-brf/components/document-download', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    href: null,
    text: null,
    target: '_blank',
    tagName: 'a',
    attributeBindings: ['href', 'target'],

    dragStart: function dragStart(event) {
      var url = this.href;
      var text = this.text;

      if (url && text) {
        var data = 'application/pdf:' + text + ':https:' + url;
        event.dataTransfer.setData("DownloadURL", data);
      }
    }
  });
});