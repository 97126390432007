define('ember-brf/models/annual-report', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AnnualReport = _emberData.default.Model.extend({
    housingCoop: _emberData.default.belongsTo('housing-coop'),
    documentId: _emberData.default.attr('string'),
    loans: _emberData.default.hasMany('loan'),

    created: _emberData.default.attr('isodate'),
    updated: _emberData.default.attr('isodate'),
    verifiedBy: _emberData.default.attr('string'),
    verifiedTime: _emberData.default.attr('datetimestring'),
    unverifiedFields: _emberData.default.attr('string'),

    year: _emberData.default.attr('number'),
    fiscalYearStart: _emberData.default.attr('datestring'),
    fiscalYearEnd: _emberData.default.attr('datestring'),
    auditFirm: _emberData.default.attr('string'),
    auditIsCertified: _emberData.default.attr({ defaultValue: null }),
    hasAuditReport: _emberData.default.attr('boolean'),
    registered: _emberData.default.attr('datestring'),
    isSigned: _emberData.default.attr({ defaultValue: null }),
    isRegistered: _emberData.default.attr({ defaultValue: null }),
    insuranceCompany: _emberData.default.attr('string'),
    financialManagementCompany: _emberData.default.attr('string'),
    technicalManagementCompany: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    housingCoopType: _emberData.default.attr('string', { defaultValue: null }),
    plotIsLeased: _emberData.default.attr({ defaultValue: null }),
    isHeatingIncludedInRent: _emberData.default.attr({ defaultValue: null }),
    essentialInformation: _emberData.default.attr({ defaultValue: null }),
    constructionYear: _emberData.default.attr('number'),
    taxAssessmentValue: _emberData.default.attr('number'),
    taxAssessmentValueBuilding: _emberData.default.attr('number'),
    taxAssessmentValueHousing: _emberData.default.attr('number'),
    taxAssessmentValuePlot: _emberData.default.attr('number'),
    taxAssessmentConstructionYear: _emberData.default.attr('number'),
    feeIncome: _emberData.default.attr('number'),
    feeIncomeCommercial: _emberData.default.attr('number'),
    feeIncomeWaterHeating: _emberData.default.attr('number'),
    feeIncomeParking: _emberData.default.attr('number'),
    plotLease: _emberData.default.attr('number'),
    rentalIncomeResidential: _emberData.default.attr('number'),
    rentalIncomeCommercial: _emberData.default.attr('number'),
    rentalIncomeParking: _emberData.default.attr('number'),
    financialIncome: _emberData.default.attr('number'),
    feeIncomeLoss: _emberData.default.attr('number'),
    feeIncomeLossCommercial: _emberData.default.attr('number'),
    feeIncomeLossParking: _emberData.default.attr('number'),
    rentalIncomeLossResidential: _emberData.default.attr('number'),
    rentalIncomeLossCommercial: _emberData.default.attr('number'),
    rentalIncomeLossParking: _emberData.default.attr('number'),
    otherIncomeLoss: _emberData.default.attr('number'),
    internalFundDeposit: _emberData.default.attr('number'),
    interestIncome: _emberData.default.attr('number'),
    otherPropertyIncome: _emberData.default.attr('number'),
    operatingIncomeRounding: _emberData.default.attr('number'),
    operatingCostMaintenance: _emberData.default.attr('number'),
    operatingCostHeating: _emberData.default.attr('number'),
    operatingCostElectricity: _emberData.default.attr('number'),
    operatingCostWaterSewer: _emberData.default.attr('number'),
    operatingCostGarbage: _emberData.default.attr('number'),
    operatingCostInsurance: _emberData.default.attr('number'),
    operatingCostCable: _emberData.default.attr('number'),
    managementFees: _emberData.default.attr('number'),
    personnelCosts: _emberData.default.attr('number'),
    boardFees: _emberData.default.attr('number'),
    operatingCostTotal: _emberData.default.attr('number'),
    plannedMaintenanceCost: _emberData.default.attr('number'),
    propertyTax: _emberData.default.attr('number'),
    depreciations: _emberData.default.attr('number'),
    otherDepreciations: _emberData.default.attr('number'),
    interestCostBuildings: _emberData.default.attr('number'),
    interestCostOther: _emberData.default.attr('number'),
    financialCostOther: _emberData.default.attr('number'),
    otherTax: _emberData.default.attr('number'),
    tangibleAssets: _emberData.default.attr('number'),
    intangibleAssets: _emberData.default.attr('number'),
    currentAssets: _emberData.default.attr('number'),
    otherAssets: _emberData.default.attr('number'),
    cash: _emberData.default.attr('number'),
    restrictedEquity: _emberData.default.attr('number'),
    unrestrictedEquity: _emberData.default.attr('number'),
    retainedEarnings: _emberData.default.attr('number'),
    netIncome: _emberData.default.attr('number'),
    netTurnover: _emberData.default.attr('number'),
    externalFund: _emberData.default.attr('number'),
    otherFunds: _emberData.default.attr('number'),
    longTermDeposits: _emberData.default.attr('number'),
    longTermRealEstateDebt: _emberData.default.attr('number'),
    longTermDebtOther: _emberData.default.attr('number'),
    shortTermDebt: _emberData.default.attr('number'),
    otherDebt: _emberData.default.attr('number'),
    shortTermClaims: _emberData.default.attr('number'),
    assumedInterest: _emberData.default.attr('number'),
    numberOfUnits: _emberData.default.attr('number'),
    numberOfRentalUnits: _emberData.default.attr('number'),
    numberOfCommercialUnits: _emberData.default.attr('number'),
    numberOfParkingSpaces: _emberData.default.attr('number'),
    totalLivingArea: _emberData.default.attr('number'),
    totalCommercialAreaHousingCoop: _emberData.default.attr('number'),
    totalRentalArea: _emberData.default.attr('number'),
    totalCommercialArea: _emberData.default.attr('number'),
    totalPlotArea: _emberData.default.attr('number'),

    title: Ember.computed('year', function () {
      return this.year || 'År saknas';
    }),

    totalRentalAreaDisabled: Ember.computed('numberOfRentalUnits', function () {
      return this.numberOfRentalUnits === 0;
    }),
    rentalIncomeResidentialDisabled: Ember.computed.alias('totalRentalAreaDisabled'),
    rentalIncomeLossResidentialDisabled: Ember.computed.alias('totalRentalAreaDisabled'),

    totalCommercialAreaDisabled: Ember.computed('numberOfCommercialUnits', function () {
      return this.numberOfCommercialUnits === 0;
    }),
    totalCommercialAreaHousingCoopDisabled: Ember.computed.alias('totalCommercialAreaDisabled'),
    feeIncomeCommercialDisabled: Ember.computed.alias('totalCommercialAreaDisabled'),
    rentalIncomeCommercialDisabled: Ember.computed.alias('totalCommercialAreaDisabled'),
    feeIncomeLossCommercialDisabled: Ember.computed.alias('totalCommercialAreaDisabled'),
    rentalIncomeLossCommercialDisabled: Ember.computed.alias('totalCommercialAreaDisabled'),

    feeIncomeParkingDisabled: Ember.computed('numberOfParkingSpaces', function () {
      return this.numberOfParkingSpaces === 0;
    }),
    rentalIncomeParkingDisabled: Ember.computed.alias('feeIncomeParkingDisabled'),
    feeIncomeLossParkingDisabled: Ember.computed.alias('feeIncomeParkingDisabled'),
    rentalIncomeLossParkingDisabled: Ember.computed.alias('feeIncomeParkingDisabled'),

    plotLeaseDisabled: Ember.computed('plotIsLeased', function () {
      return this.plotIsLeased === 0;
    }),
    renovationNeedsCommentDisabled: Ember.computed('renovationNeeds', function () {
      return this.renovationNeeds === 0;
    }),
    auditFirmDisabled: Ember.computed('auditIsCertified', function () {
      return this.auditIsCertified === 0;
    }),

    totalIncomeLoss: Ember.computed('feeIncomeLoss', 'rentalIncomeLossResidential', 'rentalIncomeLossCommercial', 'rentalIncomeLossParking', 'otherIncomeLoss', 'internalFundDeposit', function () {
      return (this.feeIncomeLoss || 0) + (this.rentalIncomeLossResidential || 0) + (this.rentalIncomeLossCommercial || 0) + (this.rentalIncomeLossParking || 0) + (this.otherIncomeLoss || 0) + (this.internalFundDeposit || 0);
    }),

    totalOperatingIncome: Ember.computed('totalIncomeLoss', 'feeIncome', 'rentalIncomeResidential', 'rentalIncomeCommercial', 'rentalIncomeParking', 'otherPropertyIncome', 'operatingIncomeRounding', function () {
      return (this.feeIncome || 0) + (this.rentalIncomeResidential || 0) + (this.rentalIncomeCommercial || 0) + (this.rentalIncomeParking || 0) + (this.otherPropertyIncome || 0) + (this.operatingIncomeRounding || 0) - this.totalIncomeLoss;
    }),

    totalIncome: Ember.computed('totalOperatingIncome', 'interestIncome', 'financialIncome', function () {
      return (this.totalOperatingIncome || 0) + (this.interestIncome || 0) + (this.financialIncome || 0);
    }),

    otherCosts: Ember.computed('operatingCostTotal', 'plotLease', 'plannedMaintenanceCost', 'operatingCostMaintenance', 'operatingCostHeating', 'operatingCostElectricity', 'operatingCostWaterSewer', 'operatingCostGarbage', 'operatingCostInsurance', 'operatingCostCable', 'managementFees', 'personnelCosts', 'boardFees', 'propertyTax', function () {
      return this.operatingCostTotal ? (this.operatingCostTotal || 0) - (this.plotLease || 0) - (this.plannedMaintenanceCost || 0) - (this.operatingCostMaintenance || 0) - (this.operatingCostHeating || 0) - (this.operatingCostElectricity || 0) - (this.operatingCostWaterSewer || 0) - (this.operatingCostGarbage || 0) - (this.operatingCostInsurance || 0) - (this.operatingCostCable || 0) - (this.managementFees || 0) - (this.personnelCosts || 0) - (this.boardFees || 0) - (this.propertyTax || 0) : null;
    }),

    totalOperatingCosts: Ember.computed('operatingCostTotal', 'depreciations', function () {
      return (this.operatingCostTotal || 0) + (this.depreciations || 0);
    }),

    totalCosts: Ember.computed('totalOperatingCosts', 'interestCostBuildings', 'interestCostOther', 'financialCostOther', function () {
      return (this.totalOperatingCosts || 0) + (this.interestCostBuildings || 0) + (this.interestCostOther || 0) + (this.financialCostOther || 0);
    }),

    totalAssets: Ember.computed('tangibleAssets', 'intangibleAssets', 'longTermDeposits', 'shortTermClaims', 'cash', 'otherAssets', function () {
      return (this.tangibleAssets || 0) + (this.intangibleAssets || 0) + (this.longTermDeposits || 0) + (this.shortTermClaims || 0) + (this.cash || 0) + (this.otherAssets || 0);
    }),

    totalEquityAndLoans: Ember.computed('unrestrictedEquity', 'restrictedEquity', 'longTermRealEstateDebt', 'longTermDebtOther', 'shortTermDebt', 'otherDebt', function () {
      return (this.unrestrictedEquity || 0) + (this.restrictedEquity || 0) + (this.longTermRealEstateDebt || 0) + (this.longTermDebtOther || 0) + (this.shortTermDebt || 0) + (this.otherDebt || 0);
    }),

    netOperatingIncome: Ember.computed('totalOperatingIncome', 'totalOperatingCosts', function () {
      return (this.totalOperatingIncome || 0) - (this.totalOperatingCosts || 0);
    }),

    netIncomeAfterFinancialItems: Ember.computed('netOperatingIncome', 'interestCostBuildings', 'interestCostOther', 'interestIncome', 'financialIncome', 'financialCostOther', function () {
      return this.netOperatingIncome + (this.interestIncome || 0) + (this.financialIncome || 0) - (this.interestCostBuildings || 0) - (this.interestCostOther || 0) - (this.financialCostOther || 0);
    }),

    totalLoans: Ember.computed('loans.@each.amount', function () {
      var loans = this.loans || [];
      return loans.map(function (l) {
        return l.amount || 0;
      }).reduce(function (a, b) {
        return a + b;
      }, 0);
    }),

    updateFiscalYear: Ember.observer('year', function () {
      var year = this.year,
          fiscalYearStart = this.fiscalYearStart,
          fiscalYearEnd = this.fiscalYearEnd;


      if (year && year > 2000 && !fiscalYearStart && !fiscalYearEnd) {
        this.set('fiscalYearStart', year + '-01-01');
        this.set('fiscalYearEnd', year + '-12-31');
      }
    }),

    updateTaxAssessment: Ember.observer('taxAssessmentValueBuilding', 'taxAssessmentValuePlot', function () {
      var taxAssessmentValuePlot = this.taxAssessmentValuePlot,
          taxAssessmentValueBuilding = this.taxAssessmentValueBuilding;


      if (taxAssessmentValuePlot && taxAssessmentValueBuilding) {
        this.set('taxAssessmentValue', taxAssessmentValuePlot + taxAssessmentValueBuilding);
      }
    }),

    fiscalYearLength: Ember.computed('fiscalYearStart', 'fiscalYearEnd', function () {
      var fiscalYearStart = this.fiscalYearStart,
          fiscalYearEnd = this.fiscalYearEnd;


      if (fiscalYearStart && fiscalYearEnd && fiscalYearStart != fiscalYearEnd) {
        return (new Date(fiscalYearEnd) - new Date(fiscalYearStart)) / (364 * 24 * 60 * 60 * 1000);
      } else {
        return 1;
      }
    }),

    feeToLivingArea: Ember.computed('feeIncome', 'totalLivingArea', 'fiscalYearLength', function () {
      var feeIncome = this.feeIncome,
          totalLivingArea = this.totalLivingArea,
          fiscalYearLength = this.fiscalYearLength;


      if (feeIncome && totalLivingArea && totalLivingArea > 0) {
        return Math.round(feeIncome / (totalLivingArea * fiscalYearLength));
      } else {
        return null;
      }
    }),

    feeToLivingAreaExcludingWaterHeating: Ember.computed('feeIncome', 'feeIncomeWaterHeating', 'totalLivingArea', 'fiscalYearLength', function () {
      var feeIncome = this.feeIncome,
          totalLivingArea = this.totalLivingArea,
          fiscalYearLength = this.fiscalYearLength;

      var feeIncomeWaterHeating = this.feeIncomeWaterHeating || 0;

      if (feeIncome && totalLivingArea && totalLivingArea > 0) {
        return Math.round((feeIncome - feeIncomeWaterHeating) / (totalLivingArea * fiscalYearLength));
      } else {
        return null;
      }
    }),

    debtToLivingArea: Ember.computed('longTermRealEstateDebt', 'totalLivingArea', function () {
      var longTermRealEstateDebt = this.longTermRealEstateDebt,
          totalLivingArea = this.totalLivingArea;


      if (longTermRealEstateDebt && totalLivingArea && totalLivingArea > 0) {
        return Math.round(longTermRealEstateDebt / totalLivingArea);
      } else {
        return null;
      }
    }),

    debtToLivingAreaIncludingRentals: Ember.computed('longTermRealEstateDebt', 'totalLivingArea', 'totalRentalArea', function () {
      var longTermRealEstateDebt = this.longTermRealEstateDebt,
          totalLivingArea = this.totalLivingArea;

      var totalRentalArea = this.totalRentalArea || 0;

      if (longTermRealEstateDebt && totalLivingArea && totalLivingArea > 0) {
        return Math.round(longTermRealEstateDebt / (totalLivingArea + totalRentalArea));
      } else {
        return null;
      }
    }),

    solidity: Ember.computed('restrictedEquity', 'unrestrictedEquity', 'longTermRealEstateDebt', 'longTermDebtOther', 'shortTermDebt', function () {
      var restrictedEquity = this.restrictedEquity,
          unrestrictedEquity = this.unrestrictedEquity,
          longTermRealEstateDebt = this.longTermRealEstateDebt,
          shortTermDebt = this.shortTermDebt;

      var longTermDebtOther = this.longTermDebtOther || 0;
      var otherDebt = this.otherDebt || 0;

      if (restrictedEquity && unrestrictedEquity && longTermRealEstateDebt && shortTermDebt) {
        var solidity = (restrictedEquity + unrestrictedEquity) / (restrictedEquity + unrestrictedEquity + longTermRealEstateDebt + longTermDebtOther + shortTermDebt + otherDebt);
        return Math.round(1000 * solidity) / 10;
      } else {
        return null;
      }
    }),

    liquidity: Ember.computed('currentAssets', 'shortTermDebt', function () {
      var currentAssets = this.currentAssets,
          shortTermDebt = this.shortTermDebt;


      if (currentAssets && shortTermDebt) {
        var liquidity = currentAssets / shortTermDebt;
        return Math.round(1000 * liquidity) / 10;
      } else {
        return null;
      }
    }),

    savings: Ember.computed('depreciations', 'netIncome', 'plannedMaintenanceCost', 'totalLivingArea', 'totalRentalArea', 'totalCommercialArea', function () {
      var depreciations = this.depreciations,
          netIncome = this.netIncome,
          totalLivingArea = this.totalLivingArea;

      var plannedMaintenanceCost = this.plannedMaintenanceCost || 0;
      var totalRentalArea = this.totalRentalArea || 0;
      var totalCommercialArea = this.totalCommercialArea || 0;

      var totalArea = totalLivingArea + totalRentalArea + totalCommercialArea;

      if (totalArea && depreciations && netIncome != null) {
        var savings = depreciations / totalArea + plannedMaintenanceCost / totalArea + netIncome / totalArea;
        return Math.round(savings);
      } else {
        return null;
      }
    }),

    energyCostToTotalArea: Ember.computed('operatingCostHeating', 'operatingCostElectricity', 'operatingCostWaterSewer', 'totalLivingArea', 'totalRentalArea', 'totalCommercialArea', function () {
      var operatingCostHeating = this.operatingCostHeating || 0;
      var operatingCostElectricity = this.operatingCostElectricity || 0;
      var operatingCostWaterSewer = this.operatingCostWaterSewer || 0;
      var totalLivingArea = this.totalLivingArea || 0;
      var totalRentalArea = this.totalRentalArea || 0;
      var totalCommercialArea = this.totalCommercialArea || 0;

      var totalEnergyCost = operatingCostHeating + operatingCostElectricity + operatingCostWaterSewer;
      var totalArea = totalLivingArea + totalRentalArea + totalCommercialArea;

      if (totalEnergyCost > 0 && totalArea > 0) {
        return Math.round(totalEnergyCost / totalArea);
      } else {
        return null;
      }
    }),

    meanLivingArea: Ember.computed('numberOfUnits', 'totalLivingArea', 'totalRentalArea', function () {
      var numberOfUnits = this.numberOfUnits;

      var totalArea = (this.totalLivingArea || 0) + (this.totalRentalArea || 0);

      if (numberOfUnits && numberOfUnits > 0) {
        return Math.round(totalArea / numberOfUnits * 10) / 10;
      } else {
        return null;
      }
    }),

    approval: Ember.computed('id', 'housingCoop.approvals.@each.annualReport', function () {
      var approvals = this.get('housingCoop.approvals') || [];
      return approvals.findBy('annualReport.id', this.id);
    }),

    document: Ember.computed('documentId', 'housingCoop.documents.@each.id', function () {
      var documents = this.get('housingCoop.documents') || [];
      return documents.findBy('id', this.documentId);
    }),

    documentLabel: Ember.computed('document.type', function () {
      var document = this.document;

      if (document && document.type == 'annualreport') {
        return 'ÅR';
      } else if (document && document.type == 'economicplan') {
        return 'Ek. plan';
      } else {
        return 'Okänd';
      }
    }),

    isApproved: Ember.computed('approval', function () {
      return !!this.approval && !this.approval.hasDirtyAttributes && this.approval.isValid;
    }),

    approvalConditions: Ember.computed('housingCoop.{name,orgNumber}', 'document.type', 'fiscalYearEnd', 'numberOfUnits', 'housingCoopType', 'numberOfRentalUnits', 'debtToLivingArea', 'isSigned', 'auditIsCertified', 'auditFirm', 'verifiedBy', 'hasDirtyAttributes', function () {
      return [{ fulfilled: this.get('document.type') == 'annualreport', text: "Dokumentet är en årsredovisning" }, { fulfilled: this.get('housingCoopType') == 'Äkta bostadsrättsförening' || this.get('housingCoopType') == 'Bostadsförening', text: "Föreningen är en äkta bostadsrättsförening eller en bostadsförening" }, { fulfilled: !!this.get('housingCoop.orgNumber'), text: "Organisationsnummer finns" }, { fulfilled: this.numberOfUnits - this.numberOfRentalUnits >= 7, text: "Det finns minst 7 bostadsrätter" }, { fulfilled: this.debtToLivingArea < 9000, text: "Skuldkvoten är under 9000 kr/kvm" }, { fulfilled: !!this.isSigned, text: "Årsredovisningen är underskriven" }, { fulfilled: this.auditIsCertified === 0 || this.auditIsCertified === 1 && !!this.auditFirm, text: "Revisor är ifyllt" }, { fulfilled: !!this.fiscalYearEnd, text: "Bokslutsdatum är ifyllt" }, { fulfilled: !!this.verifiedBy, text: "Klarmarkerat" }, { fulfilled: !this.hasDirtyAttributes, text: "Inga osparade ändringar" }];
    }),

    canBeApproved: Ember.computed('approvalConditions.@each.fulfilled', function () {
      return this.approvalConditions.isEvery('fulfilled', true);
    }),

    approvalText: Ember.computed('housingCoop.{name,orgNumber}', 'fiscalYearEnd', 'numberOfUnits', 'numberOfRentalUnits', 'debtToLivingArea', 'longTermRealEstateDebt', 'totalLivingArea', function () {
      return this.get('housingCoop.name') + ', orgnr ' + this.get('housingCoop.orgNumber') + ', År ' + this.fiscalYearEnd + ' snabbgranskad ok. ' + this.numberOfUnits + ' st lgh varav ' + (this.numberOfUnits - this.numberOfRentalUnits) + ' brf' + ', brf-yta ' + this.totalLivingArea + ' kvm' + ', lån ' + this.longTermRealEstateDebt + ' kr' + ', lån/kvm ' + this.debtToLivingArea + ' kr';
    }),

    onLoansChanged: Ember.observer('loans.@each.{bank,interest,amount,isFloating,dateOfChange}', function () {
      this.send('becomeDirty');
    })
  });

  exports.default = AnnualReport;
});