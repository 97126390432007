define('ember-brf/controllers/housing-coops/housing-coop', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    updates: Ember.inject.service(),

    reload: function reload() {
      var _this = this;

      if (this.housingCoop) {
        this.store.findRecord('housing-coop', this.housingCoop.id, { reload: true, include: 'areas,addresses,documents,annualReports,approvals' }).then(function (housingCoop) {
          _this.set('housingCoop', housingCoop);
        });
      }
    },
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.updates.on('event', function (data) {
        var model = data.model,
            id = data.id;


        if (model == 'housing-coop' && '' + id == '' + _this2.get('housingCoop.id')) {
          _this2.reload();
        }
      });
    },


    actions: {
      reload: function reload() {
        this.reload();
      }
    }
  });
});