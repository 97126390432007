define('ember-brf/components/document-upload', ['exports', 'ember-brf/utils/get-cookie'], function (exports, _getCookie) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'card',
    accept: 'application/pdf,application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.text,application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.presentation',
    queueName: 'documents',
    type: null,
    housingCoop: null,
    baseUrl: '/api/booli/housing-coops/',
    fileQueue: Ember.inject.service(),

    queue: Ember.computed('name', function () {
      var queueName = this.queueName;

      var queues = this.fileQueue;
      return queues.find(queueName) || queues.create(queueName);
    }),

    isFailed: Ember.computed('queue.files.{length,@each.state}', function () {
      return this.queue.files.length > 0 && !this.queue.files.every(function (f) {
        return ['queued', 'uploading', 'uploaded'].includes(f.state);
      });
    }),

    isUploading: Ember.computed('isFailed', 'queue.files.length', function () {
      return !this.isFailed && this.queue.files.length > 0;
    }),

    url: Ember.computed('baseUrl', 'housingCoop', function () {
      if (this.housingCoop) {
        return this.baseUrl + this.housingCoop.id + '/documents';
      } else {
        return null;
      }
    }),

    actions: {
      uploadImage: function uploadImage(file) {
        var _this = this;

        if (this.url) {
          file.upload({
            url: this.url,
            method: 'POST',
            headers: {
              "X-CSRFToken": (0, _getCookie.default)('csrftoken')
            },
            data: {
              data: JSON.stringify({
                type: this.type,
                housingCoopId: this.get('housingCoop.id'),
                source: 'booli.brf'
              })
            }
          }).then(function () {
            _this.onUpload();
          });
        }
      },
      clear: function clear() {
        var queue = this.queue;

        queue.files.forEach(function (file) {
          file.set('state', 'aborted');
        });
      }
    }
  });
});