define('ember-brf/components/content-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',

    isUnverified: Ember.computed('model.unverifiedFields', {
      get: function get() {
        var unverifiedFields = (this.get('model.unverifiedFields') || '').split(',');
        return unverifiedFields.includes(this.field.slug);
      },
      set: function set(key, value) {
        var slug = this.field.slug;

        var unverifiedFields = (this.get('model.unverifiedFields') || '').split(',').filter(function (s) {
          return s != '';
        });
        if (value && !unverifiedFields.includes(slug)) {
          unverifiedFields.push(slug);
        } else if (!value) {
          unverifiedFields = unverifiedFields.filter(function (s) {
            return s != slug;
          });
        }
        this.set('model.unverifiedFields', unverifiedFields ? unverifiedFields.join(',') : null);
        return value;
      }
    })
  });
});