define('ember-brf/models/housing-coop', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var HousingCoop = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    created: _emberData.default.attr('isodate'),
    link: _emberData.default.attr('string'),
    orgNumber: _emberData.default.attr('string'),

    documents: _emberData.default.hasMany('document'),
    annualReports: _emberData.default.hasMany('annual-report'),
    approvals: _emberData.default.hasMany('approval'),

    addresses: _emberData.default.attr(),
    areas: _emberData.default.attr(),

    fullName: Ember.computed('name', 'orgNumber', function () {
      var name = this.name,
          orgNumber = this.orgNumber;


      if (orgNumber) {
        return name + ' (' + orgNumber + ')';
      } else {
        return name;
      }
    }),

    county: Ember.computed('areas', function () {
      var areas = this.areas || [];
      return areas.findBy('type', 'county');
    }),

    municipality: Ember.computed('areas', function () {
      var areas = this.areas || [];
      return areas.findBy('type', 'municipality');
    }),

    municipalityName: Ember.computed('municipality', function () {
      var name = this.get('municipality.name');
      if (name.indexOf('kommun') == -1) {
        if (!/.*[aeiouyåäös]$/.test(name)) {
          name += 's';
        }
        name += ' kommun';
      }

      return name;
    }),

    annualReport: Ember.computed('annualReports.@each.year', function () {
      var annualReports = this.annualReports || [];
      return annualReports.filterBy('verifiedBy').sortBy('year').lastObject;
    }),

    approval: Ember.computed('approvals.@each.validUntil', function () {
      var approvals = this.approvals || [];
      return approvals.sortBy('validUntil').lastObject;
    }),

    documentsToInput: Ember.computed('documents.@each.type', 'annualReports.@each.documentId', function () {
      var documents = this.documents || [];
      var annualReports = this.annualReports || [];
      var documentsToInput = documents.filter(function (d) {
        return d.type == 'annualreport' || d.type == 'economicplan';
      });
      return documentsToInput.map(function (d) {
        d.set("annualReport", annualReports.findBy('documentId', d.id));
        return d;
      }).sortBy('id').reverse();
    }),

    annualReportDocuments: Ember.computed('documentsToInput.@each.type', function () {
      var documents = this.documentsToInput || [];
      return documents.filterBy('type', 'annualreport');
    }),

    economicplanDocuments: Ember.computed('documentsToInput.@each.type', function () {
      var documents = this.documentsToInput || [];
      return documents.filterBy('type', 'economicplan');
    }),

    byelaws: Ember.computed('documents.@each.type', function () {
      var documents = this.documents || [];
      return documents.filterBy('type', 'byelaws').reverse();
    }),

    otherDocuments: Ember.computed('documents.@each.type', function () {
      var documents = this.documents || [];
      return documents.filter(function (d) {
        return d.type != 'byelaws' && d.type != 'annualreport' && d.type != 'economicplan';
      }).reverse();
    }),

    center: Ember.computed('addresses.@each.{latitude,longitude}', function () {
      var addresses = this.addresses || [];
      if (addresses.length == 0) {
        return null;
      }

      return {
        latitude: addresses.mapBy('latitude').reduce(function (a, b) {
          return a + b;
        }, 0) / addresses.length,
        longitude: addresses.mapBy('longitude').reduce(function (a, b) {
          return a + b;
        }, 0) / addresses.length
      };
    })
  });

  exports.default = HousingCoop;
});